@font-face {
    font-family: 'Archivo';
    src: url('/fonts/Archivo-Regular.woff2') format('woff2'), url('/fonts/Archivo-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recoleta';
    src: url('/fonts/Recoleta-Medium.woff2') format('woff2'), url('/fonts/Recoleta-Medium.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Archivo';
    src: url('/fonts/Archivo-SemiBold.woff2') format('woff2'), url('/fonts/Archivo-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

:root {
    --color-accent: #c8fe59;
    --color-dark: #000;
    --color-border: #d8d9db;
    --color-faded: #92969c;
    --color-light: #f0f0f0;
    --color-orange: #fb7e59;
    --color-orange-hover: #ef7551;
    --color-purple: #cec7fd;
    --color-purple-hover: #c3bbf4;
    --color-white: #fff;
    --color-blue: #38869e;
    --color-blue-hover: #2f788e;
    --color-green: #497a51;
    --color-sand: #ddd1c2;
    --wrap: 12rem;
    --full: 13.36rem;
    --wrap-mobile: 3.43rem;
}

::selection {
    color: #000;
    background: #c8fe59;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Archivo';
    color: var(--color-dark);
}

html {
    font-size: 6.9vw;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--color-light);
}

body {
    font-size: 0.16rem;
    line-height: 1.375;
}

h1,
h2,
h3,
h4 {
    font-weight: 400;
    color: var(--color-dark);
    margin: 0;
}

b,
strong {
    font-weight: 600;
}

.h1 {
    font-size: 0.62rem;
    line-height: 1.1;
    font-family: 'Recoleta';
}

.h2 {
    font-size: 0.48rem;
    line-height: 1.125;
    margin-bottom: 0.08rem;
    font-family: 'Recoleta';
}

.h3 {
    font-size: 0.32rem;
    margin-bottom: 0.08rem;
    line-height: 1.1875;
    font-family: 'Recoleta';
}

img {
    object-fit: cover;
    object-position: center;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

svg {
    pointer-events: none;
}

p {
    margin: 0 0 1.375em 0;
}

b,
strong {
    font-weight: 600;
}

.noscroll {
    position: relative;
    overflow: hidden;
}

.image {
    transition: opacity 300ms ease-in-out;
}

.imageLoading {
    transform: translate3d(0, 0, 0);
    opacity: 0;
}

.masonry {
    display: flex;
    width: auto;
}

@media only screen and (min-width: 1366px) {
    html {
        font-size: 100px;
    }
}

@media only screen and (max-width: 750px) {
    html {
        font-size: 26.496vw;
    }
    .h1 {
        font-size: 0.4rem;
    }
    .h2 {
        font-size: 0.32rem;
    }
}

/* .reveal-1 {
    opacity: 0;
    transform: translate3d(0, 0.6rem, 0);
    transition: opacity 600ms ease-in-out, transform 600ms ease-in-out;
}

.reveal-2 {
    opacity: 0;
    transform: translate3d(0, 0.6rem, 0);
    transition: opacity 600ms 300ms ease-in-out, transform 600ms 300ms ease-in-out;
}

.reveal-3 {
    opacity: 0;
    transform: translate3d(0, 0.6rem, 0);
    transition: opacity 600ms 600ms ease-in-out, transform 600ms 600ms ease-in-out;
}

.reveal-4 {
    opacity: 0;
    transform: translate3d(0, 0.6rem, 0);
    transition: opacity 600ms 900ms ease-in-out, transform 600ms 900ms ease-in-out;
} */

.reveal-1 {
    opacity: 0;
    transform: translate3d(0, 0.9rem, 0);
    transition: opacity 900ms cubic-bezier(0.25, 0.1, 0.25, 1), transform 900ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

.reveal-2 {
    opacity: 0;
    transform: translate3d(0, 1.2rem, 0);
    transition: opacity 800ms 100ms cubic-bezier(0.25, 0.1, 0.25, 1), transform 800ms 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

.reveal-3 {
    opacity: 0;
    transform: translate3d(0, 0.9rem, 0);
    transition: opacity 900ms 600ms cubic-bezier(0.25, 0.1, 0.25, 1), transform 900ms 600ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

.reveal-4 {
    opacity: 0;
    transform: translate3d(0, 0.9rem, 0);
    transition: opacity 900ms 900ms cubic-bezier(0.25, 0.1, 0.25, 1), transform 900ms 900ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

.reveal-2.show,
.reveal-3.show,
.reveal-4.show,
.reveal-1.show {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}